import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-think-tags",
  templateUrl: "./think-tags.component.html",
  styleUrls: ["./think-tags.component.scss"]
})
export class ThinkTagsComponent implements OnInit {
  tags = [{ tagName: "test" }, { tagName: "one" }, { tagName: "two" }];
  constructor() {}

  ngOnInit() {}
}
