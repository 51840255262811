import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatCard,
  MatMenuModule,
  MatCardModule,
  MatInputModule,
  MatChipsModule,
  MatNavList
} from "@angular/material";
import { ThinkContentComponent } from "./think-content/think-content.component";
import { ThinkNavComponent } from "./think-nav/think-nav.component";
import { ThinkTagsComponent } from "./think-tags/think-tags.component";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [
    AppComponent,
    ThinkContentComponent,
    ThinkNavComponent,
    ThinkTagsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatListModule,
    MatInputModule,
    FlexLayoutModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
