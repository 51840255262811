/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./think-tags.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./think-tags.component";
var styles_ThinkTagsComponent = [i0.styles];
var RenderType_ThinkTagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThinkTagsComponent, data: {} });
export { RenderType_ThinkTagsComponent as RenderType_ThinkTagsComponent };
export function View_ThinkTagsComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_ThinkTagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-think-tags", [], null, null, null, View_ThinkTagsComponent_0, RenderType_ThinkTagsComponent)), i1.ɵdid(1, 114688, null, 0, i2.ThinkTagsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThinkTagsComponentNgFactory = i1.ɵccf("app-think-tags", i2.ThinkTagsComponent, View_ThinkTagsComponent_Host_0, {}, {}, []);
export { ThinkTagsComponentNgFactory as ThinkTagsComponentNgFactory };
