/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./think-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i7 from "@angular/material/menu";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "./think-nav.component";
var styles_ThinkNavComponent = [i0.styles];
var RenderType_ThinkNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThinkNavComponent, data: {} });
export { RenderType_ThinkNavComponent as RenderType_ThinkNavComponent };
export function View_ThinkNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-toolbar", [["class", "mat-elevation-z4 mat-toolbar"], ["color", "primary"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["thinkpct"])), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "span", [["class", "example-fill-remaining-space"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 12, "mat-menu", [], null, null, null, i6.View__MatMenu_0, i6.RenderType__MatMenu)), i1.ɵdid(7, 1294336, [["menu", 4]], 3, i7._MatMenu, [i1.ElementRef, i1.NgZone, i7.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 2, { _allItems: 1 }), i1.ɵqud(603979776, 3, { items: 1 }), i1.ɵqud(603979776, 4, { lazyContent: 0 }), i1.ɵprd(2048, null, i7.MatMenu, null, [i7._MatMenu]), i1.ɵprd(2048, null, i7.MAT_MENU_PANEL, null, [i7.MatMenu]), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_MatMenuItem_0, i6.RenderType_MatMenuItem)), i1.ɵdid(14, 180224, [[2, 4], [3, 4]], 0, i7.MatMenuItem, [i1.ElementRef, i5.DOCUMENT, i8.FocusMonitor, [2, i7.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵted(-1, 0, ["Item 1"])), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_MatMenuItem_0, i6.RenderType_MatMenuItem)), i1.ɵdid(17, 180224, [[2, 4], [3, 4]], 0, i7.MatMenuItem, [i1.ElementRef, i5.DOCUMENT, i8.FocusMonitor, [2, i7.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵted(-1, 0, ["Item 2"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 14).role; var currVal_4 = i1.ɵnov(_v, 14)._highlighted; var currVal_5 = i1.ɵnov(_v, 14)._triggersSubmenu; var currVal_6 = i1.ɵnov(_v, 14)._getTabIndex(); var currVal_7 = i1.ɵnov(_v, 14).disabled.toString(); var currVal_8 = (i1.ɵnov(_v, 14).disabled || null); _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = i1.ɵnov(_v, 17).role; var currVal_10 = i1.ɵnov(_v, 17)._highlighted; var currVal_11 = i1.ɵnov(_v, 17)._triggersSubmenu; var currVal_12 = i1.ɵnov(_v, 17)._getTabIndex(); var currVal_13 = i1.ɵnov(_v, 17).disabled.toString(); var currVal_14 = (i1.ɵnov(_v, 17).disabled || null); _ck(_v, 16, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
export function View_ThinkNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-think-nav", [], null, null, null, View_ThinkNavComponent_0, RenderType_ThinkNavComponent)), i1.ɵdid(1, 114688, null, 0, i9.ThinkNavComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThinkNavComponentNgFactory = i1.ɵccf("app-think-nav", i9.ThinkNavComponent, View_ThinkNavComponent_Host_0, {}, {}, []);
export { ThinkNavComponentNgFactory as ThinkNavComponentNgFactory };
