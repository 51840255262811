import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-think-content',
  templateUrl: './think-content.component.html',
  styleUrls: ['./think-content.component.scss']
})
export class ThinkContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
