<mat-toolbar color="primary" class="mat-elevation-z4">
  <span>thinkpct</span>

  <!-- This fills the remaining space of the current row -->
  <span class="example-fill-remaining-space"></span>
  <!-- <form class="example-form">
    <mat-form-field>
      <input matInput placeholder="Search" />
    </mat-form-field>
  </form> -->
</mat-toolbar>
<mat-menu #menu="matMenu">
  <button mat-menu-item>Item 1</button>
  <button mat-menu-item>Item 2</button>
</mat-menu>
