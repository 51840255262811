<div fxLayout="row">
  <div fxFlex="20"><app-think-tags></app-think-tags></div>
  <div fxFlex>
    <h1>Thinking In Percents</h1>

    <p>
      Budget everything. But not in disparate units... base your budgets in
      <strong>percentage</strong> terms of the maximum available units for any
      given effort.
    </p>

    <ul>
      <li>Budget <strong>Money</strong> in % terms of availble cash or cash flow</li>
      <li>Budget <strong>Time</strong> in % terms of hours in a day or days in a week</li>
      <li>Budget <strong>Investments</strong> in % terms of total investable assets</li>
    </ul>

    <h3>Think in percents and be more happy.</h3>
  </div>
  <div fxFlex="20"></div>
</div>